import { Trans } from '@lingui/macro'
import { BrowserEvent, InterfaceElementName, InterfacePageName, SharedEventName } from '@uniswap/analytics-events'
import { Trace, TraceEvent } from 'analytics'
import { AboutFooter } from 'components/About/AboutFooter'
import Card, { CardType } from 'components/About/Card'
import { MAIN_CARDS, MORE_CARDS } from 'components/About/constants'
import ProtocolBanner from 'components/About/ProtocolBanner'
import { useAccountDrawer } from 'components/AccountDrawer'
import { BaseButton } from 'components/Button'
import { AppleLogo } from 'components/Logo/AppleLogo'
import { useDisableNFTRoutes } from 'hooks/useDisableNFTRoutes'
import Swap from 'pages/Swap'
import { parse } from 'qs'
import { useEffect, useMemo, useRef } from 'react'
import { ArrowDownCircle } from 'react-feather'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { Link as NativeLink } from 'react-router-dom'
import { useAppSelector } from 'state/hooks'
import styled, { css } from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { useIsDarkMode } from 'theme/components/ThemeToggle'
import { TRANSITION_DURATIONS } from 'theme/styles'
import { Z_INDEX } from 'theme/zIndex'
import { getDownloadAppLinkProps } from 'utils/openDownloadApp'
import { motion } from 'framer-motion';

import DescSrc from 'assets/images/desc.png';
import DescVerticalSrc from 'assets/images/desc_vertical.png';
import { darken } from 'polished'
import { keyframes } from 'styled-components'
import { atom, useAtom } from 'jotai'

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  padding: ${({ theme }) => theme.navHeight}px 0px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  overflow-x: hidden;
`

const Gradient = styled.div<{ isDarkMode: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  width: 100%;
  min-height: 550px;
  ${({ isDarkMode }) =>
    isDarkMode
      ? css`
          background: linear-gradient(rgba(8, 10, 24, 0) 0%, rgb(8 10 24 / 100%) 45%);
        `
      : css`
          background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255 255 255 /100%) 45%);
        `};
  z-index: ${Z_INDEX.under_dropdown};
  pointer-events: none;
  height: ${({ theme }) => `calc(100vh - ${theme.mobileBottomBarHeight}px)`};
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    height: 100vh;
  }
`

const GlowContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: hidden;
  height: ${({ theme }) => `calc(100vh - ${theme.mobileBottomBarHeight}px)`};
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    height: 100vh;
  }
`

const Glow = styled.div`
  position: absolute;
  top: 68px;
  bottom: 0;
  background: radial-gradient(72.04% 72.04% at 50% 3.99%, #ff37eb 0%, rgba(166, 151, 255, 0) 100%);
  filter: blur(72px);
  border-radius: 24px;
  max-width: 480px;
  width: 100%;
  height: 100%;
`

const ContentContainer = styled.div<{ isDarkMode: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 0 40px;
  max-width: min(720px, 90%);
  min-height: 535px;
  z-index: ${Z_INDEX.under_dropdown};
  transition: ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.ease} opacity`};
  height: ${({ theme }) => `calc(100vh - ${theme.navHeight + theme.mobileBottomBarHeight}px)`};
  pointer-events: none;
  * {
    pointer-events: auto;
  }
`

const TitleText = styled.h1<{ isDarkMode: boolean }>`
  color: transparent;
  font-size: 36px;
  line-height: 44px;
  font-weight: 535;
  text-align: center;
  margin: 0 0 24px;
  ${({ isDarkMode }) =>
    isDarkMode
      ? css`
          background: linear-gradient(20deg, rgba(255, 244, 207, 1) 10%, rgba(255, 87, 218, 1) 100%);
        `
      : css`
          background: linear-gradient(10deg, rgba(255, 79, 184, 1) 0%, rgba(255, 159, 251, 1) 100%);
        `};
  background-clip: text;
  -webkit-background-clip: text;

  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    font-size: 48px;
    line-height: 56px;
  }

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    font-size: 64px;
    line-height: 72px;
  }
`

const SubText = styled.div`
  color: ${({ theme }) => theme.neutral2};
  font-size: 16px;
  line-height: 24px;
  font-weight: 535;
  text-align: center;
  max-width: 500px;
  margin: 0 0 32px;

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    font-size: 20px;
    line-height: 28px;
  }
`

const SubTextContainer = styled.div`
  display: flex;
  justify-content: center;
`

const LandingButton = styled(BaseButton)`
  padding: 16px 0px;
  border-radius: 24px;
`

const ButtonCTA = styled(LandingButton)`
  background: linear-gradient(93.06deg, #ff00c7 2.66%, #ff9ffb 98.99%);
  border: none;
  color: ${({ theme }) => theme.white};
  transition: ${({ theme }) => `all ${theme.transition.duration.medium} ${theme.transition.timing.ease}`};

  &:hover {
    box-shadow: 0px 0px 16px 0px #ff00c7;
  }
`

const ButtonCTAText = styled.p`
  margin: 0px;
  font-size: 16px;
  font-weight: 535;
  white-space: nowrap;

  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    font-size: 20px;
  }
`

const ActionsContainer = styled.span`
  max-width: 300px;
  width: 100%;
  pointer-events: auto;
`

const LearnMoreContainer = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.neutral3};
  cursor: pointer;
  font-size: 20px;
  font-weight: 535;
  margin: 36px 0;
  display: flex;
  visibility: hidden;
  pointer-events: auto;
  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    visibility: visible;
  }

  transition: ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.ease} opacity`};

  &:hover {
    opacity: 0.6;
  }
`

const LearnMoreArrow = styled(ArrowDownCircle)`
  margin-left: 14px;
  size: 20px;
`

const AboutContentContainer = styled.div<{ isDarkMode: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 5rem;
  width: 100%;
  ${({ isDarkMode }) =>
    isDarkMode
      ? css`
          background: linear-gradient(179.82deg, rgba(0, 0, 0, 0) 0.16%, #050026 99.85%);
        `
      : css`
          background: linear-gradient(179.82deg, rgba(255, 255, 255, 0) 0.16%, #eaeaea 99.85%);
        `};
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    padding: 0 96px 5rem;
  }
`

const CardGrid = styled.div<{ cols: number }>`
  display: grid;
  gap: 12px;
  width: 100%;
  padding: 24px 0 0;
  max-width: 1440px;
  scroll-margin: ${({ theme }) => `${theme.navHeight}px 0 0`};

  grid-template-columns: 1fr;
  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    // At this screen size, we show up to 2 columns.
    grid-template-columns: ${({ cols }) =>
      Array.from(Array(cols === 2 ? 2 : 1))
        .map(() => '1fr')
        .join(' ')};
    gap: 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    // at this screen size, always show the max number of columns
    grid-template-columns: ${({ cols }) =>
      Array.from(Array(cols))
        .map(() => '1fr')
        .join(' ')};
    gap: 32px;
  }
`

const LandingSwapContainer = styled.div`
  height: ${({ theme }) => `calc(100vh - ${theme.mobileBottomBarHeight}px)`};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`

const SwapCss = css`
  * {
    pointer-events: none;
  }

  &:hover {
    transform: translateY(-4px);
    transition: ${({ theme }) => `transform ${theme.transition.duration.medium} ${theme.transition.timing.ease}`};
  }
`

const LinkCss = css`
  text-decoration: none;
  max-width: 480px;
  width: 100%;
`

const LandingSwap = styled(Swap)`
  ${SwapCss}
  &:hover {
    border: 1px solid ${({ theme }) => theme.accent1};
  }
`

const Link = styled(NativeLink)`
  ${LinkCss}
`

const stateAtom = atom<'ready' | 'mounted'>('ready');

export default function Landing() {
  const [state, setState] = useAtom(stateAtom);

  useEffect(() => {
    setTimeout(() => {
      setState('mounted')
    }, 0)
  }, [])

  return (
    <Trace page={InterfacePageName.LANDING_PAGE} shouldLogImpression>
      <Container>
        <AnimationGroup>
          <motion.div 
            className='gradient'
            initial={{
              scale: 0,
              opacity: 0,
            }}
            variants={{
              ready: {
                scale: 0,
                opacity: 0,
              },
              mounted: {
                scale: 2,
                opacity: 1,
              },
            }}
            animate={state}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
          />

          <motion.svg 
            className="character" 
            viewBox="0 0 1770 652" 
            fill="none"
            initial={{
              scale: 0,
              y: '-10%',
              opacity: 0,
            }}
            variants={{
              ready: {
                scale: 0,
                y: '-10%',
                opacity: 0,
              },
              mounted: {
                scale: 2,
                y: '0%',
                opacity: 1,
              },
            }}
            animate={state}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
              delay: 0.1,
            }}
            >
            <g className='head' transform="translate(321 14)">
              <g>
                <path d="M563.936 161.688C563.291 156.745 560.236 152.574 557.506 148.576C553.086 142.107 548.445 135.806 543.549 129.691C540.244 125.676 536.521 120.64 532.159 117.715L532.06 117.692C530.881 117.443 528.68 124.904 528.494 125.519C526.136 133.195 524.812 141.26 523.836 149.214C522.848 157.221 522.605 165.233 522.605 173.292C522.663 177.847 523.354 182.332 519.544 185.546C516.006 188.528 513.033 190.878 509.193 193.193C507.979 193.924 506.829 194.62 505.836 195.293C488.528 207.031 469.622 214.823 448.044 219.11C438.35 221.036 428.075 222.011 417.493 222.011C400.306 222.011 383.137 219.795 366.421 215.855C358.301 213.946 350.274 211.643 342.392 208.922C338.367 207.529 334.382 206.027 330.438 204.414C328.33 203.549 323.416 200.411 321.499 202.998C321.244 203.346 321.116 203.799 321.058 204.222C319.92 212.444 333.813 216.557 341.352 219.47C356.221 225.22 370.492 228.904 384.972 230.726C397.135 232.257 408.072 233 418.428 233C440.063 233 458.72 229.722 475.453 222.98C488.452 217.741 501.468 210.703 514.13 202.047C516.732 200.271 519.375 198.484 522.035 196.691C528.099 192.601 534.372 188.377 540.314 184.078C541.388 183.301 542.492 182.517 543.613 181.717C550.868 176.559 558.366 171.221 563.024 164.636C563.384 164.125 563.727 163.644 563.965 163.179C564.006 162.704 563.994 162.199 563.93 161.677L563.936 161.688ZM548.271 164.601C543.532 168.268 532.635 178.09 531.543 177.957C530.184 177.795 530.986 163.58 531.027 162.454C531.358 152.666 531.439 142.635 535.046 133.392C535.307 132.725 535.871 132.667 536.283 133.253C541.714 140.871 547.772 148.054 552.645 156.055C554.927 159.803 550.844 162.611 548.271 164.601Z" fill="#FF0054"/>
                <path d="M462.832 196.819C453.458 196.819 444.507 193.268 437.624 186.811C430.91 180.516 427.448 172.904 423.621 163.597C419.869 154.477 417.162 144.886 415.588 135.092C413.712 123.425 411.586 108.02 416.691 95.2039C417.69 92.8135 422.07 82.5267 430.782 78.0128C430.782 78.0128 423.353 91.5139 422.738 105.659C422.337 114.913 423.597 124.399 425.334 133.543C428.18 148.53 433.024 161.073 439.733 170.815C445.198 178.758 454.433 183.892 463.262 183.892C466.811 183.892 470.209 183.103 473.357 181.543C482.406 177.058 488.557 168.535 493.163 159.826C507.05 133.555 506.237 103.797 497.664 75.9009C495.283 68.1611 491.902 60.7288 487.221 54.0972C483.579 48.9451 478.375 44.5936 475.186 39.2094C474.391 37.8634 475.268 36.0706 476.795 35.8269C478.555 35.5484 480.524 37.7415 481.674 38.8323C489.434 46.1892 496.927 54.3757 502.218 63.8503C508.113 74.404 510.599 86.3734 511.813 98.2964C512.342 103.512 512.603 108.751 512.661 113.991C512.684 116.132 512.655 118.325 512.568 120.5C511.883 137.895 508.439 154.326 502.868 166.765C495.643 182.9 484.991 192.688 471.208 195.862C468.455 196.494 465.638 196.819 462.832 196.819Z" fill="#FF0054"/>
                <path d="M391.937 197.869C390.351 197.869 388.713 197.776 387.081 197.591C378.369 196.61 369.784 193.1 362.913 187.71C356.878 182.976 351.25 175.868 346.638 167.154C329.788 135.342 329.231 94.1828 345.215 62.3011C349.612 53.5344 355.397 44.8257 363.958 42.7196C366.485 42.0988 370.289 42.8124 373.623 45.0462C367.983 49.7864 363.151 55.3272 359.294 61.6513C354.067 70.215 350.28 79.9622 347.8 90.2549C344.878 102.393 344.1 115.139 345.488 128.153C345.749 130.607 346.092 133.085 346.516 135.504C350.007 155.689 357.418 170.275 368.547 178.868C374.965 183.823 382.998 186.445 391.768 186.445C398.634 186.445 404.43 184.362 410.204 180.684C415.53 177.29 425.386 166.301 425.386 166.301C425.462 183.173 409.983 193.164 405.58 195.148C401.573 196.953 396.984 197.869 391.937 197.869Z" fill="#FF0054"/>
                <path d="M425.386 162.837C424.747 152.887 420.751 141.55 419.63 135.214C417.725 124.446 419.52 112.006 419.677 101.064C419.822 90.7771 424.312 90.1911 417.888 80.9138C413.16 74.0849 403.739 65.3646 395.753 61.4483C392.326 59.7715 385.873 57.7292 383.66 59.1333C367.437 69.4085 363.738 90.4812 364.684 108.189C364.731 109.03 364.742 109.86 364.806 110.701C365.068 113.938 365.858 117.205 367.792 119.874C369.9 122.781 373.432 122.81 376.307 121.017C379.426 119.067 383.549 118.754 386.634 120.947C389.503 122.984 390.758 127.12 390.624 130.491C390.461 134.698 388.074 137.1 384.078 138.138C380.338 139.107 375.633 139.92 374.982 144.491C373.53 154.691 378.502 164.415 385.861 171.221C389.451 174.54 393.784 177.4 398.273 179.349C404.105 181.885 410.918 182.784 416.779 179.895C423.388 176.634 425.805 169.486 425.381 162.849L425.386 162.837Z" fill="#FF0054"/>
                <path d="M503.931 154.436C512.888 129.459 511.186 99.1144 502.729 74.2299C499.738 65.4284 489.521 45.1506 479.92 41.5012C474.211 39.3313 469.494 43.7233 465.934 47.8659C459.551 55.2924 453.76 63.3629 449.81 72.3617C445.663 81.8131 444.368 92.1637 443.723 102.375C443.322 108.67 443.16 118.51 450.281 121.313C455.171 123.239 458.424 116.921 462.716 116.103C473.246 114.095 477.759 139.275 464.975 137.314C457.75 136.206 453.667 138.521 453.963 146.598C454.462 160.162 466.422 170.536 478.665 174.354C491.269 178.282 499.842 165.848 503.931 154.436Z" fill="#FF0054"/>
                <path d="M425.281 91.9433C424.921 92.2856 424.364 92.3088 423.969 92.0071C423.725 91.8214 423.51 91.6474 423.353 91.5255C422.946 91.2006 422.627 90.9454 422.331 90.7423C420.211 89.2802 418.097 87.8123 415.994 86.3328C411.644 83.2868 407.659 80.4438 403.814 77.6415C395.253 71.4044 387.615 65.5503 380.465 59.7483C373.582 54.074 366.026 49.2816 358.719 44.6517C354.92 42.2439 350.994 39.7548 347.236 37.1846L346.336 36.5696C340.272 32.4212 333.54 28.3018 329.283 22.1518C328.841 21.5193 328.394 20.4112 328.737 19.6511C329.358 18.2645 331.002 18.2238 332.291 18.5604C334.191 19.0593 335.991 19.8484 337.832 20.4924C345.145 23.0569 351.731 26.9848 358.132 31.3014C364.783 35.7863 371.549 40.0972 378.147 44.6749C384.014 48.742 389.805 52.9078 395.497 57.2071L396.891 58.263C403.832 63.5138 413.334 70.714 420.693 74.5723C420.896 74.6767 421.053 74.7695 421.187 74.8508C421.704 75.1525 422.493 75.605 423.597 75.605C426.002 75.605 428.087 72.8839 429.754 71.4276C432.902 68.6659 436.096 65.9622 439.268 63.2295C458.052 47.0363 475.744 29.8799 497.635 17.5624C500.533 15.9321 503.664 13.7912 507.143 14.0232C508.218 14.0929 509.472 14.6789 510.053 15.6188C510.854 16.9242 509.908 18.102 508.944 18.9897C495.486 31.4581 480.826 42.3483 466.816 54.1378C459.109 60.6244 451.628 67.3779 444.269 74.2532C441.881 76.4811 439.494 78.7149 437.107 80.9428C433.988 83.5421 431.043 86.3966 428.191 89.1583C427.239 90.0808 426.269 91.0208 425.299 91.9433H425.281Z" fill="#FF0054"/>
              </g>
            </g>
            <g className='left'>
              <path d="M102.686 313.5C100.106 314.396 98.2407 310.577 97.2991 308.713C95.7854 305.724 94.5101 302.533 93.1573 299.473C89.3134 290.789 86.435 281.645 84.7902 272.28C83.932 267.404 83.5744 261.965 84.7484 257.106C85.8331 252.618 88.7949 248.913 91.1728 245.052C93.1453 241.848 95.2252 238.699 97.3468 235.591C101.524 229.459 105.988 223.519 110.905 217.967C114.212 214.237 118.229 210.018 123.604 210C125.13 209.994 126.626 210.46 127.913 211.279L127.937 211.297L128.05 211.357C130.183 212.516 131.53 214.68 132.877 216.616C138.181 224.26 143.235 232.25 146.197 241.137C146.983 243.504 149.009 250.783 144.856 251.04C140.356 251.321 137.043 246.498 134.945 243.169C133.247 240.468 131.745 237.653 130.255 234.832C128.932 232.322 127.829 229.268 125.636 227.403C122.263 224.535 119.009 228.127 117.001 230.762C113.014 236.003 109.009 241.251 105.231 246.647C102.901 249.976 100.291 253.18 98.634 256.915C97.0786 260.435 97.4064 264.051 98.0619 267.732C98.8009 271.886 99.7246 276.01 100.899 280.068C101.387 281.753 102.984 289.959 105.523 289.684C106.745 289.552 108.533 285.805 109.141 284.926C112.15 280.552 115.064 276.105 118.473 272.029C119.278 271.067 120.249 270.009 121.596 270.434C122.716 270.786 123.658 272.25 123.485 273.416C122.383 281 119.576 288.166 115.863 294.829C113.914 298.331 111.757 301.708 109.492 305.007C108.384 306.621 107.251 308.211 106.101 309.788C105.166 311.067 104.266 312.944 102.681 313.494L102.686 313.5Z" fill="#FF0054"/>
              <path d="M38.5878 325.875C36.2636 326.4 33.4448 325.133 31.4662 324.159C26.4245 321.673 22.67 317.968 18.4924 314.322C14.0884 310.473 9.50555 306.816 5.49482 302.537C3.54011 300.451 1.69864 298.18 0.673606 295.514C-0.726871 291.881 0.375632 288.564 1.09077 284.954C4.48171 267.772 11.1563 251.916 17.825 235.846C19.3387 232.206 21.3649 228.59 24.6307 226.391C31.055 222.064 39.9704 228.549 45.3756 232.033C49.6188 234.764 54.1599 237.22 58.0812 240.418C60.8643 242.689 60.4531 244.9 58.7845 247.637C56.1504 251.958 50.0061 248.103 47.0026 246.215C42.9144 243.639 38.4507 239.39 33.26 239.39C28.8262 239.39 27.1456 245.217 25.614 248.504C23.0336 254.05 20.8345 259.769 18.9454 265.59C16.4424 273.294 12.6045 285.528 12.5151 288.606C12.384 293.279 15.9835 298.807 19.726 301.371C28.8977 307.664 32.0443 307.664 35.1194 302.698C37.7713 298.413 39.0407 293.243 41.3411 289.61C44.7499 284.225 49.4698 290.446 48.9394 294.702C48.1408 301.174 46.8655 307.575 45.328 313.904C44.3566 317.902 43.4626 324.769 38.5938 325.869L38.5878 325.875Z" fill="#FF0054"/>
              <path d="M72.5327 319.175C70.8581 319.671 69.0584 319.438 67.1394 318.153C63.0035 315.386 59.7318 311.34 56.5315 307.581C50.6913 300.732 46.8414 295.359 40.578 288.928C39.4278 287.733 38.6531 285.187 38.5995 282.438C38.5399 279.318 39.9582 276.061 40.882 273.127C42.8486 266.863 45.0655 260.618 47.8009 254.648C51.6686 246.209 56.1442 238.069 60.5184 229.887C63.4803 224.347 66.8236 215 74.4219 215C74.6126 215 74.8092 215 75.0059 215.018C81.7699 215.382 90.7687 220.964 95.876 225.279C100.614 229.284 101.466 235.529 98.1644 240.782C97.6221 241.649 97.2824 241.655 97.0917 241.655C95.584 241.655 92.9141 239.527 91.5673 237.991C91.5673 237.991 91.2693 237.651 91.2634 237.645C88.1764 234.125 82.5744 229.224 77.6758 228.812L77.4851 228.794H77.2943C75.9356 228.829 74.6543 229.553 73.8557 230.73C69.5172 237.149 66.3885 244.278 63.3611 251.378C61.1501 256.566 58.8736 261.724 56.7044 266.935C54.8331 271.429 51.5733 281.225 52.2944 283.006C53.9749 287.171 56.907 290.691 60.0536 293.823C62.4255 296.184 66.3647 300.995 70.0655 300.923C73.1585 300.863 78.9928 284.577 82.2943 278.756C83.6352 276.39 85.5244 273.569 87.8188 271.298C89.7139 269.421 92.5387 270.724 93.0333 273.115C93.8855 277.209 92.0023 281.069 90.7687 284.846C89.2133 289.61 87.3837 294.283 85.6138 298.963C83.8021 303.756 79.1775 317.209 72.5327 319.175Z" fill="#FF0054"/>
            </g>
            <g className='right'>
              <path d="M782.921 318.935C783.223 318.917 783.531 318.858 783.839 318.745C787.667 317.308 788.584 311.744 789.632 308.348C791.472 302.375 793.354 296.484 794.662 290.351C795.626 285.82 796.419 281.26 797.177 276.694C797.905 272.306 798.976 267.722 797.739 263.322C796.828 260.086 794.626 257.509 792.644 254.873C789.626 250.865 786.135 247.237 782.454 243.841C778.856 240.516 775.069 237.404 771.429 234.127C769.352 232.251 767.192 230.469 764.961 228.789C763.014 227.322 760.967 225.446 758.576 224.858C754.031 223.736 749.865 226.598 746.972 229.84C743.38 233.86 740.273 238.2 737.379 242.742C735.841 245.165 731.012 250.04 732.178 253.24C733.432 256.666 739.806 261.22 742.167 258.305C746.717 252.688 749.025 242.273 755.996 239.589C759.198 238.36 761.766 241.709 764.103 243.669C767.896 246.845 771.382 250.396 774.696 254.066C776.968 256.583 779.164 259.178 781.318 261.796C783.152 264.029 785.608 266.321 786.194 269.266C786.738 272.015 785.886 274.83 785.058 277.424C784.063 280.542 783.199 283.695 782.33 286.847C781.821 288.682 781.513 290.962 780.495 292.625C777.56 297.423 770.098 283.451 768.831 281.444C767.038 278.612 765.044 275.388 761.659 273.446C759.34 272.116 756.73 273.921 757.274 276.546C759.056 285.102 762.541 293.183 767.027 300.653C769.252 304.358 771.737 307.914 774.418 311.299C776.341 313.721 779.406 319.107 782.909 318.923L782.921 318.935Z" fill="#FF0054"/>
              <path d="M810.39 316.429C810.929 316.429 811.663 316.09 812.461 315.473C816.367 312.457 820.208 308.959 823.888 305.082L824.356 304.589C830.031 298.616 835.221 292.197 840.854 286.165C846.032 280.619 846.861 275.744 844.618 268.458C844.221 267.176 843.783 265.881 843.351 264.629L843.209 264.213C837.339 246.988 827.948 231.372 820.184 215.007C810.639 194.891 789.051 213.03 781.524 225.185C781.264 225.606 781.003 226.129 781.228 226.568C781.394 226.895 781.773 227.049 782.122 227.156C787.193 228.723 792.265 226.705 796.549 224.003C799.833 221.937 804.822 216.646 808.769 220.381C809.491 221.064 810 221.937 810.497 222.798C816.373 233.082 821.006 244.037 825.705 254.897C826.646 257.07 827.587 259.249 828.534 261.416C829.699 264.088 831.007 267.283 831.865 270.685C832.327 272.52 832.783 275.281 831.416 278.077C827.812 285.458 822.835 291.218 816.45 295.867C814.633 297.191 813.243 297.506 812.556 295.362C811.544 292.221 809.58 286.307 809.349 285.583C807.656 280.399 804.851 271.873 801.177 267.782C798.768 265.104 794.211 265.668 793.72 268.257C793.253 270.744 796.099 275.287 796.922 277.608C798.786 282.869 800.573 288.474 801.993 293.99C803.017 297.969 803.638 302.321 804.727 306.394C805.348 308.716 807.1 316.417 810.408 316.417L810.39 316.429Z" fill="#FF0054"/>
              <path d="M850.743 318.982C856.679 319.416 863.679 311.305 866.952 307.873C869.976 304.696 872.857 301.389 875.769 298.111C878.325 295.237 881.213 292.601 883.13 289.228C887.746 281.112 882.781 270.958 879.39 263.328C879.059 262.592 878.734 261.856 878.414 261.119C874.674 252.587 870.638 244.179 866.283 235.938C864.082 231.769 861.78 227.667 859.436 223.581C857.625 220.417 855.483 217.05 851.968 215.613C846.944 213.565 842.003 216.735 838.275 219.912C836.245 221.64 834.399 223.558 832.476 225.398C830.588 227.209 828.014 228.712 826.925 231.176C825.96 233.361 826.481 235.938 827.481 238.016C827.949 238.984 828.487 239.316 829.629 239.316C836.168 239.316 840.63 232.642 847.027 232.642C849.057 232.642 850.82 233.693 851.868 235.528C856.738 244.043 860.898 252.842 864.33 260.324C864.762 261.268 865.241 262.259 865.739 263.281C869.922 271.908 874.704 278.891 868.851 287.792C866.526 291.324 855.584 302.262 854.826 302.297C852.181 302.422 846.151 290.333 843.595 285.565C843.015 284.484 842.482 282.774 841.565 281.943C840.524 280.999 837.304 282.228 836.884 283.184C836.204 284.734 836.624 287.406 836.884 288.985C837.216 291.022 837.547 293.064 837.878 295.101C839.068 302.422 841.263 311.097 846.589 316.856C847.861 318.228 849.27 318.869 850.749 318.976L850.743 318.982Z" fill="#FF0054"/>
            </g>
          </motion.svg>

          <motion.img 
            className="desc" 
            src={DescSrc}
            initial={{
              y: 100,
              opacity: 0,
            }}
            variants={{
              ready: {
                y: 50,
                opacity: 0,
              },
              mounted: {
                y: 0,
                opacity: 1,
              },
            }}
            animate={state}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
              delay: 0.3
            }}
          />
          <motion.img 
            className="desc_vertical" 
            src={DescVerticalSrc}
            initial={{
              y: 100,
              opacity: 0,
            }}
            variants={{
              ready: {
                y: 100,
                opacity: 0,
              },
              mounted: {
                y: 0,
                opacity: 1,
              },
            }}
            animate={state}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
              delay: 0.3
            }}
          />

          <motion.p 
            className="desc"
            initial={{
              y: 100,
              opacity: 0,
            }}
            variants={{
              ready: {
                y: 100,
                opacity: 0,
              },
              mounted: {
                y: 0,
                opacity: 1,
              },
            }}
            animate={state}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
              delay: 0.4
            }}
          >
            Experience the First V3 AMM with Concentrated Liquidity Functionality
          </motion.p>
          <motion.p 
            className="desc_vertical"
            initial={{
              y: 100,
              opacity: 0,
            }}
            variants={{
              ready: {
                y: 100,
                opacity: 0,
              },
              mounted: {
                y: 0,
                opacity: 1,
              },
            }}
            animate={state}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
              delay: 0.4
            }}
          >
              Experience the First V3 AMM<br/>with Concentrated Liquidity Functionality
          </motion.p>

          <motion.div
            className='cta'
            initial={{
              y: 100,
              opacity: 0,
            }}
            variants={{
              ready: {
                y: 100,
                opacity: 0,
              },
              mounted: {
                y: 0,
                opacity: 1,
              },
            }}
            animate={state}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
              delay: 0.6
            }}
          >
            <Link to="/swap">
              Swap Now
            </Link>
          </motion.div>
        </AnimationGroup>
      </Container>
    </Trace>
  )
}

const scaling = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
`;

const mouth = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const head = keyframes`
  0% {
    transform: translate(0, 0px) rotate(0deg);
  }
  25% {
    transform: translate(2px, -5px) rotate(2deg);
  }
  50% {
    transform: translate(0, 10px) rotate(0);
  }
  75% {
    transform: translate(-2px, -5px) rotate(2deg);
  }
  100% {
    transform: translate(0, 0) rotate(0);
  }
`;

const finger = keyframes`
  0% {
    transform: translate(0, 0px) rotate(0deg);
  }
  50% {
    transform: translate(0, 5px) rotate(2deg);
  }
  100% {
    transform: translate(0, 15px) rotate(2deg);
  }
`;

const finger2 = keyframes`
  0% {
    transform: translate(0, 0px) rotate(0deg);
  }
  50% {
    transform: translate(0, 7px) rotate(-2deg);
  }
  100% {
    transform: translate(0, 12px) rotate(-2deg);
  }
`;

const AnimationGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  margin-top: 0;
  width: 885px;
  user-select: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: 485px;
  }

  .gradient {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: radial-gradient(50% 50% at 50% 50%,#ff7a002b 30%,transparent 50%);
      animation: ${scaling} 3s linear infinite alternate;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: radial-gradient(50% 50% at 50% 50%,#ff005429 50%,transparent 70%);
      animation: ${scaling} 3s linear infinite alternate;
    }
  }

  .head {
    transform: translate(25%, 25%);

    g {
      animation: ${head} 3s linear infinite alternate;
    }

    path:nth-child(1) {
      animation: ${mouth} 5s linear infinite alternate;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
      transform: translate(26%, 26%);
    }
  }

  .left {
    transform: translate(25%, 25%);

    path:nth-child(1) {
      animation: ${finger} 1s linear infinite alternate;
    }

    path:nth-child(2) {
      animation: ${finger} 1s 0.5s linear infinite alternate;
    }

    path:nth-child(3) {
      animation: ${finger} 1s 1s linear infinite alternate;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
      transform: translate(34%, 27%);
    }
  }

  .right {
    transform: translate(24%, 27%);

    path:nth-child(1) {
      animation: ${finger2} 1s linear infinite alternate;
    }

    path:nth-child(2) {
      animation: ${finger2} 1s 0.5s linear infinite alternate;
    }

    path:nth-child(3) {
      animation: ${finger2} 1s 1s linear infinite alternate;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
      transform: translate(16.5%, 26.5%);
    }
  }

  img.desc {
    position: relative;
    margin-top: -5.6%;
    width: 112%;
    aspect-ratio: 991/121;

    @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
      display: none;
    }
  }

  img.desc_vertical {
    position: relative;
    margin-top: -5.6%;
    width: 52%;
    aspect-ratio: 742/691;

    @media screen and (min-width: ${({ theme }) => theme.breakpoint.md + 1}px) {
      display: none;
    }
  }

  p.desc {
    margin: 0;
    margin-top: 79px;
    font-size: 20px;
    font-weight: 500;
    color: #CC0043;

    @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
      display: none;
    }
  }

  p.desc_vertical {
    margin: 0;
    margin-top: 25px;
    font-size: 14px;
    font-weight: 500;
    color: #CC0043;
    text-align: center;

    @media screen and (min-width: ${({ theme }) => theme.breakpoint.md + 1}px) {
      display: none;
    }
  }

  .cta a {
    display: inline-block;
    margin-top: 35px;
    padding: 14px 36px;
    line-height: 24px;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    width: auto;
    border-radius: 38px;
    outline: none;
    border: 1px solid transparent;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    z-index: 1;
    &:disabled {
      opacity: 50%;
      cursor: auto;
      pointer-events: none;
    }

    will-change: transform;
    transition: transform 450ms ease;
    transform: perspective(1px) translateZ(0);

    > * {
      user-select: none;
    }

    > a {
      text-decoration: none;
    }
    background-color: ${({ theme }) => theme.special};
    color: ${({ theme }) => theme.accent3};
    &:focus {
      box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.special)};
      background-color: ${({ theme }) => darken(0.1, theme.special)};
    }
    &:hover {
      background-color: ${({ theme }) => darken(0.2, theme.special)};
    }
    &:active {
      box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.2, theme.special)};
      background-color: ${({ theme }) => darken(0.2, theme.special)};
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
      margin-top: 25px;
      padding: 9px 26px;
      line-height: 28px;
      font-size: 18px;
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`

