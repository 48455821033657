import { Trans } from '@lingui/macro'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { getChainInfo } from 'constants/chainInfo'
import { ArrowUpRight } from 'react-feather'
import styled from 'styled-components'
import { ExternalLink, HideSmall } from 'theme'
import { colors } from 'theme/colors'
import { useDarkModeManager } from 'theme/components/ThemeToggle'

import Column from '../Column'

const L2Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`

const BodyText = styled.div`
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 20px;
`
const RootWrapper = styled.div`
  margin-top: 16px;
`

const SHOULD_SHOW_ALERT = {
  [ChainId.OPTIMISM]: true,
  [ChainId.OPTIMISM_GOERLI]: true,
  [ChainId.ARBITRUM_ONE]: true,
  [ChainId.ARBITRUM_GOERLI]: true,
  [ChainId.POLYGON]: true,
  [ChainId.POLYGON_MUMBAI]: true,
  [ChainId.CELO]: true,
  [ChainId.CELO_ALFAJORES]: true,
  [ChainId.BNB]: true,
  [ChainId.AVALANCHE]: true,
  [ChainId.BASE]: true,
}

type NetworkAlertChains = keyof typeof SHOULD_SHOW_ALERT

const ContentWrapper = styled.div`
  background: transparent;
  border: 1px solid ${colors.gray800};
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  width: 100%;
`

const Header = styled.h2`
  font-weight: 535;
  font-size: 16px;
  margin: 0;
`

const LinkOutToBridge = styled(ExternalLink)`
  align-items: center;
  color: white;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding: 15px 16px;
  text-decoration: none !important;
  width: 100%;
`

const StyledArrowUpRight = styled(ArrowUpRight)`
  margin-top: -18px;
  margin-right: -2px;
  width: 24px;
  height: 24px;
`
export function NetworkAlert() {
  return (
    <RootWrapper>
      <ContentWrapper>
        <LinkOutToBridge href="https://bridge.xpla.io/">
          <Column gap="xs">
            <span style={{ fontWeight: 600, color: colors.yellowVibrant }}>
              XPLA Bridge
            </span>
            <span style={{ fontWeight: 400, color: '#DCD6D8' }}>
              Deposit tokens to the XPLA network.
            </span>
          </Column>
          <StyledArrowUpRight color={colors.yellowVibrant} />
        </LinkOutToBridge>
      </ContentWrapper>
    </RootWrapper>
  )
}
